import React, {useCallback, useEffect, useState} from 'react';
import {Button, Input, message, Select} from 'antd';
import b_ from 'b_';
import { Link } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import TextArea from 'antd/lib/input/TextArea';
import PrintAccountState from '../../../Components/Prints/PrintAccountStatus';
import EditableField from '../../../Components/EditableField';
import {
    changeStatusAccount,
    noArgAction,
    updateCommentAccount,
    updateCountryAccount,
    updateDriverAccount,
    updateLoginAccount,
    updateProxyAccount,
} from '../../../Reducers/accounts';
import ChangePassword from '../ChangePassword';
import CountrySelector from '../Selectors/CountrySelector';
import ChangeToken from '../ChangeToken';
import Expanded from './Expanded';
import './styles.scss';
import useStorageState from "../../../Utils/useStorageState";
import TableSettings from "./TableSettings";
import ResizeTable from "../../../Components/ResizebleTitle";
import AccountsChosen from "../AccountsChosen";
import AccountGroups from "../AccountGroups";
import {fetchConfig} from "../../../Reducers/config";
import ResetProfile from "../AccountResetProfileButton";
import {removeUserDataDir} from "../../../Reducers/driver";
import DefaultProfile from "../AccountUseDefaultProfileButton";
import {defaultUserDataDir} from "../../../Reducers/driver";

import {fetchCheckPerDay } from '../../../Reducers/config';

const b = b_.lock('AccountsTable');

function AccountsTable({
                           list,
                           pagination,
                           onChange,
                           isLoading,
                           afterUpdate,
                           selected,
                           setSelected,
                           onFilter,
                           filters,
                           sorter,
                           accountsLocal,
                           addAccountChosen,
                           removeAccountChosen,
                           removeAccountFromGroup,
                           countries
                       }) {
  const dispatch = useDispatch();

  const loadings = useSelector(state => ({
    proxy: state.accounts.updateProxy.isLoading,
    configs: state.config.configs.isLoading,
    driver: state.accounts.updateDriver.isLoading,
    comment: state.accounts.updateComment.isLoading,
    status: state.accounts.changeStatus.isLoading,
    login: state.accounts.updateLogin.isLoading,
    country: state.accounts.updateCountry.isLoading,
  }), shallowEqual);

  const {accountCodeFilters} = useSelector(
      (state) => {
          if (!state.config.configs.payload) return {accountCodeFilters: null};

          try {
              let accountCodeFilters = state.config.configs.payload.find(({name}) => name === 'resultCodeFilter');
              accountCodeFilters = accountCodeFilters ? JSON.parse(accountCodeFilters.value) : null;

              return {accountCodeFilters};
          } catch (e) {
              return {accountCodeFilters: null};
          }
      },
      shallowEqual
  );

  useEffect(() => {
      !accountCodeFilters && dispatch(fetchConfig())
  }, [accountCodeFilters])

  const updateCountry = useCallback((id, country) => {
    dispatch(updateCountryAccount({ id, country })).then(() => {
      message.success('Country has been updated!');
      afterUpdate();
    }).catch(error => {
      console.log(error);
      message.error('Can\'t update country.');
    });
  }, [dispatch, afterUpdate]);

  const updateLogin = useCallback((id, login) => {
    dispatch(updateLoginAccount({ id, login })).then(() => {
      message.success('Login has been updated!');
      afterUpdate();
    }).catch(error => {
      console.log(error);
      message.error('Can\'t update login');
    });
  }, [dispatch, afterUpdate]);

  const updateProxy = useCallback((id, proxy) => {
    dispatch(updateProxyAccount({ id, proxy })).then(() => {
      message.success('Proxy has been updated!');
      afterUpdate();
    }).catch(error => {
      console.log(error);
      message.error('Can\'t update proxy');
    });
  }, [dispatch, afterUpdate]);

  const updateDriver = useCallback((id, driver) => {
    dispatch(updateDriverAccount({ id, driver })).then(() => {
      message.success('Driver has been updated!');
      afterUpdate();
    }).catch(error => {
      console.log(error);
      message.error('Can\'t update driver');
    });
  }, [dispatch, afterUpdate]);

  const updateComment = useCallback((id, comment) => {
    dispatch(updateCommentAccount({ id, comment })).then(() => {
      message.success('Comment has been updated!');
      afterUpdate();
    }).catch(error => {
      console.log(error);
      message.error('Can\'t update comment');
    });
  }, [dispatch, afterUpdate]);

  const updateStatus = useCallback((id, status) => {
    dispatch(changeStatusAccount({ id, action: status.toLowerCase() })).then(() => {
      message.success('Status has been updated!');
      afterUpdate();
    }).catch(error => {
      console.log(error);
      message.error('Can\'t update status');
    });
  }, [dispatch, afterUpdate]);

  const removeUserData = useCallback((id) => {
    dispatch(removeUserDataDir(id)).then(() => {
      message.success('Data user Chrome remove!');
      afterUpdate();
    }).catch(error => {
      console.log(error);
      message.error('Can\'t remove data Chrome user');
    })
  }, [dispatch]);

  const start = useCallback((id) => {
    dispatch(noArgAction({ action: 'start', accountIds: [id] })).then(() => {
        message.success('Chrome started!');
        afterUpdate();
    }).catch(error => {
        console.log(error);
        message.error('Cannot start Chrome');
    })
  }, [dispatch]);

    const defaultUserData = useCallback((id) => {
        dispatch(defaultUserDataDir(id)).then(() => {
            message.success('Default User Chrome Data Chrome copied!');
            afterUpdate();
        }).catch(error => {
            console.log(error);
            message.error('Can\'t use default user chrome data ');
        })
    }, [dispatch]);

    const [Checkboxs, setCheckbox] = useState([]);

    const [AllCheckbox, setAllCheckbox] = useStorageState('codeStocks', {
        checkedList: Checkboxs,
        indeterminate: false,
        checkAll: true,
    });

    const onChangeCheckList = useCallback((checkedList) => {
        setAllCheckbox({
            checkedList,
            indeterminate: !!checkedList.length && checkedList.length < columns.length,
            checkAll: checkedList.length === columns.length,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [Checkboxs]);

  useEffect(() => {
    dispatch(fetchCheckPerDay());
  }, [dispatch]);

  const limitCheckPerDay = useSelector(state => state.config.checkPerDay, shallowEqual);
  const platforms = limitCheckPerDay && limitCheckPerDay.payload && JSON.parse(limitCheckPerDay.payload)

  const GetColumns = () => {
      return [
          {
              dataIndex: "id",
              title: "ID",
              align: "center",
              width: "76px",
              sorter,
              sortOrder: sorter.field === 'id' ? sorter.order : null,
              render: id => <Link to={`/account/${id}`}>{id}</Link>
          },
          {
              dataIndex: "platform",
              title: "Platform",
              align: "center",
              width: "106px",
              filters: [
                  { text: 'R-Airbnb', value: 'R-Airbnb' },
                  { text: 'R-PSN', value: 'R-PSN' },
                  { text: 'R-Amazon', value: 'R-Amazon' },
                  { text: 'R-XBox', value: 'R-XBox' },
                  { text: 'R-Steam', value: 'R-Steam' },
                  { text: 'R-Nintendo', value: 'R-Nintendo' },
                  { text: 'R-Google', value: 'R-Google' },
                  { text: 'R-Blizzard', value: 'R-Blizzard' },
                  { text: 'R-Walmart', value: 'R-Walmart' },
                  { text: 'R-Apple', value: 'R-Apple' },
                  { text: 'R-Ebay', value: 'R-Ebay' },
                  { text: 'R-Netflix', value: 'R-Netflix' },
                  { text: 'R-IKEA', value: 'R-IKEA' },
                  { text: 'R-Spotify', value: 'R-Spotify' },
                  { text: 'R-Zalando', value: 'R-Zalando' },
                  { text: 'R-Macys', value: 'R-Macys' },
                  { text: 'R-Gamestop', value: 'R-Gamestop' },
                  { text: 'R-Gap', value: 'R-Gap' },
                  { text: 'R-H&M', value: 'R-H&M' },
                  { text: 'R-Nike', value: 'R-Nike' },
                  { text: 'R-Starbucks', value: 'R-Starbucks' },
                  { text: 'R-OldNavy', value: 'R-OldNavy' },
                  { text: 'R-KarmaKoin', value: 'R-KarmaKoin' },
                  { text: 'R-Cabelas', value: 'R-Cabelas' },
                  { text: 'R-Hulu', value: 'R-Hulu' },
                  { text: 'R-BestBuy', value: 'R-BestBuy' },
                  { text: 'R-BarnesAndNoble', value: 'R-BarnesAndNoble' },
                  { text: 'R-MYTOYS', value: 'R-MYTOYS' },
                  { text: 'R-Hotels', value: 'R-Hotels' },
              ],
              filterMultiple: false,
              filteredValue: filters['platform'] || null,
          },
          {
              dataIndex: "login",
              title: "Login",
              align: "center",
              width: "277px",
              render: (login, rec) => (
                  <EditableField handleSave={(newLogin) => updateLogin(rec.id, newLogin)}
                                 title="Update Login"
                                 withTitle
                                 isLoading={loadings.login || isLoading}
                                 iconClassName={b('edit')}
                                 titlePopover="Edit Login"
                                 initialValue={login}
                                 changeBlock={({onChange, ...props}) => (
                                     <Input {...props}
                                            size="small"
                                            onChange={e => onChange(e.target.value)}/>
                                 )}>
                      {login}
                  </EditableField>
              ),
          },
          {
              dataIndex: "status",
              title: "Status",
              align: "center",
              width: "135px",
              sorter,
              sortOrder: sorter.field === 'status' ? sorter.order : null,
              filters: [
                  {text: 'Enabled', value: 'Enabled'},
                  {text: 'Disabled', value: 'Disabled'},
                  {text: 'TempInvalid', value: 'TempInvalid'},
                  {text: 'Invalid', value: 'Invalid'},
              ],
              filterMultiple: false,
              filteredValue: filters['status'] || null,
              render: (status, rec) => (
                <>
                  <PrintAccountState
                      status={status}
                      field={"status"}
                      rec={rec}
                      handleSave={(newStatus) => updateStatus(rec.id, newStatus)}/>

                  <EditableField handleSave={(newStatus) => updateStatus(rec.id, newStatus)}
                          title="Update Status"
                          withTitle
                          titlePopover="Edit Status"
                          isLoading={loadings.status || isLoading}
                          iconClassName={b('edit')}
                          initialValue={status}
                          changeBlock={(props) => (
                            <Select {...props} size="small">
                              <Select.Option value="Disable">Disable</Select.Option>
                              <Select.Option value="Enable">Enable</Select.Option>
                            </Select>
                          )}>
                  </EditableField>
                </>
              ),
          },
          {
              dataIndex: "country",
              title: "Country",
              align: "center",
              width: "146px",
              sorter,
              sortOrder: sorter.field === 'country' ? sorter.order : null,
              ...(countries ? {
                  filteredValue: filters['country'] || null,
                  filters: countries.map(e => ({text: e, value: e})).sort((a, b) => a.text.localeCompare(b.text)),
                  filterMultiple: true,
              } : {}),
              render: (value, rec) => (
                  <EditableField handleSave={(newValue) => updateCountry(rec.id, newValue)}
                                 title="Update Country"
                                 titlePopover="Edit Country"
                                 iconClassName={b('edit')}
                                 withTitle
                                 isLoading={loadings.country || isLoading}
                                 initialValue={value}
                                 changeBlock={(props) => (
                                     <CountrySelector {...props}
                                                      size="small"
                                                      className="w100"
                                     />
                                 )}>
                      {value}
                  </EditableField>)
          },
          {
              dataIndex: "proxy",
              title: "Proxy",
              align: "center",
              width: "333px",
              render: (value, rec) => (
                  <EditableField handleSave={(newProxy) => updateProxy(rec.id, newProxy)}
                                 title="Update Proxy"
                                 titlePopover="Edit Proxy"
                                 iconClassName={b('edit')}
                                 withTitle
                                 isLoading={loadings.proxy || isLoading}
                                 initialValue={value}
                                 changeBlock={({onChange, ...props}) => (
                                     <Input {...props}
                                            size="small"
                                            onChange={e => onChange(e.target.value)}/>
                                 )}>
                      {value || <i>none</i>}
                  </EditableField>
              )
          },
          {
              dataIndex: "driver",
              title: "Driver",
              align: "center",
              width: "100px",
              sorter,
              sortOrder: sorter.field === 'driver' ? sorter.order : null,
              render: (value, rec) => (
                  <EditableField handleSave={(newDriver) => updateDriver(rec.id, newDriver)}
                                 title="Update Driver"
                                 titlePopover="Edit Driver"
                                 isLoading={loadings.driver || isLoading}
                                 iconClassName={b('edit')}
                                 withTitle
                                 initialValue={value}
                                 changeBlock={({onChange, ...props}) => (
                                     <Input {...props}
                                            size="small"
                                            type="number"
                                            onChange={e => onChange(e.target.value)}/>
                                 )}>
                      {value}
                  </EditableField>
              )
          },
          {
              dataIndex: "comment",
              title: "Comment",
              align: "center",
              width: "297px",
              sorter,
              sortOrder: sorter.field === 'comment' ? sorter.order : null,
              render: (value, rec) => (
                  <EditableField handleSave={(newComment) => updateComment(rec.id, newComment)}
                                 title="Update Comment"
                                 titlePopover="Edit Comment"
                                 isLoading={loadings.comment || isLoading}
                                 iconClassName={b('edit')}
                                 withTitle
                                 initialValue={value}
                                 changeBlock={({onChange, ...props}) => (
                                     <TextArea {...props}
                                               size="small"
                                               onChange={e => onChange(e.target.value)}
                                     />
                                 )}>
                      {value}
                  </EditableField>
              )
          },
          {
              dataIndex: "resultCode",
              title: "Result Code",
              align: "center",
              width: "167px",
              sorter,
              sortOrder: sorter.field === 'resultCode' ? sorter.order : null,
              ...(accountCodeFilters ? {
                filteredValue: filters['resultCode'] || null,
                filters: accountCodeFilters.map(e => ({text: e, value: e})),
                filterMultiple: true,
          } : {}),
          },
          {
              dataIndex: "id",
              title: "Actions",
              align: "start",
              width: "204px",
              key: "group",
              filters: accountsLocal.nameGroups,
              filterMultiple: false,
              filteredValue: filters['group'] || null,
              render: (id, rec) => <div className={b('actions')}>
                  <AccountsChosen accountId={id}
                                  accounts={accountsLocal.groups}
                                  groupAccounts={accountsLocal.nameGroups}
                                  addAccountChosen={addAccountChosen}
                                  removeAccountChosen={removeAccountChosen}
                  />
                  <AccountGroups accountId={id} removeAccountFromGroup={removeAccountFromGroup} accounts={accountsLocal}/>
                  <ChangePassword className="mr-small" accountId={id}/>
                  <ChangeToken token={rec.authToken} accountId={id}/>
                  <ResetProfile accountId={id} removeUserData={removeUserData}/>
                  <DefaultProfile accountId={id} defaultUserData={defaultUserData}/>
                  <Button type={"link"} style={{padding: 0, height: 21}}
                          onClick={() => start(id)}>Start Chrome</Button>
              </div>
          },
          {
            dataIndex: "state",
            title: "State",
            align: "center",
            width: "170px",
            render: (value, record) => {
              const platform = platforms && Object.entries(platforms).filter(([key, v]) => record.platform === key)
              const platformSet = platform && platform[0]

              return value && value.hasOwnProperty('lastCheckTs')
              && <div>
                  <div>Last check: {(new Date(value.lastCheckTs)).toLocaleString('ru', {hour: "numeric", minute: "numeric", day: "numeric", year: "numeric", month: "numeric"})}</div>
                  <div>Checks per day: {
                      platformSet
                      ? `${value.checksPerDay === null ? 0 : value.checksPerDay}/${parseInt(platformSet[1])}`
                      : `${value.checksPerDay === null ? 0 : value.checksPerDay}/∞`
                    }
                  </div>
                </div>
            }
        },
      ]
  }

    const [columns, setColumns] = useState(GetColumns());

    useEffect(() => {
        let allCheckList = []

        if(list.length && !Checkboxs.length) {
            GetColumns().forEach((e) => {
                allCheckList.push( e[Object.keys(e)[1]])
                setCheckbox(allCheckList)
            })
        }

    }, [list, AllCheckbox])

    useEffect(() => {
        if(AllCheckbox.checkAll) {
            setAllCheckbox({
                checkedList: GetColumns().map(e => e.title),
                indeterminate: false,
                checkAll: true,
            })
        }
    },[AllCheckbox.checkAll])

    const onChangeCheckAll = useCallback((e) => {
        setAllCheckbox({
            checkedList: (e.target.checked && columns.map(e => e.title)) || [],
            indeterminate: false,
            checkAll: e.target.checked,
        });
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [Checkboxs]);

    useEffect(() => {
        setColumns(GetColumns())
    }, [list,
        isLoading,
        countries,
        filters,
        limitCheckPerDay
    ])

    const columnsFilter = columns.filter(e => AllCheckbox.checkedList.indexOf(e.title) !== -1);
    const loader = Object.keys(loadings).some(e => !!loadings[e]) || isLoading

    return <ResizeTable
                  bordered
                  className={b()}
                  dataSource={list}
                  scrollToFirstRowOnChange={true}
                  scroll={{ x: "max-content", y: "calc(100vh - 125px)" }}
                  size="middle"
                  onChange={onChange}
                  pagination={pagination}
                  loading={loader}
                  onFilter={onFilter}
                  columns={columnsFilter}
                  rowClassName={() => b('lock')}
                  rowKey="id"
                  rowSelection={{
                    selectedRowKeys: selected,
                    onChange: setSelected,
                  }}
                  title={() => <TableSettings
                      onChangeCheckAll={onChangeCheckAll}
                      allChecks={AllCheckbox}
                      Checkboxs={AllCheckbox.checkedList}
                      TableColumn={() =>GetColumns()}
                      setCheckbox={onChangeCheckList}
                  />}
                  expandable={{
                    expandedRowRender: record => <Expanded record={record} />,
                    rowExpandable: record => !!record.resultDetails,
                  }}
    />
}

export default AccountsTable;
