import React, { useCallback } from 'react';
import { Col, message, Row, Input } from 'antd';
import b_ from 'b_';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { updateConfig } from '../../../../Reducers/config';
import EditableField from '../../../../Components/EditableField';
import './styles.scss';

const b = b_.lock('ConfigItem');

function ConfigItem({ item, fetch }) {
  const dispatch = useDispatch();

  const handleSave = useCallback((value) => {
    dispatch(updateConfig({ name: item.name, value })).then(() => {
      message.success(`${item.name} has been updated!`);
      fetch();
    });
  }, [dispatch, item.name, fetch]);

  const isLoading = useSelector(state => state.config.updateConfig.isLoading, shallowEqual);

  return (
    <Row className={b()}>
      <Col flex="200px"className={b('title')}>
        {item.name}
      </Col>
      <Col flex="auto">
        <EditableField handleSave={handleSave}
                       withTitle
                       title={item.name}
                       initialValue={item.value}
                       iconClassName={b('icon')}
                       isLoading={isLoading}
                       changeBlock={props => <Input.TextArea {...props} onChange={e => props.onChange(e.target.value)} />}
        >
          {item.value}
        </EditableField>
      </Col>
    </Row>
  );
}

export default ConfigItem;
